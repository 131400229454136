
<template>
  <common-trs-page-container :padding="0">
    <template v-slot:page-content>
      <common-trs-card class="pa-2 pb-0 mb-2">
        <v-card-title class="mt-3">
         <span
          class="pr-3 cursor-pointer"
          @click="close"
        > <v-icon>
          mdi-arrow-left</v-icon></span> <span class="trsText--text font-weight-medium text-h4">Create Financing Round </span>
        </v-card-title>
        <financing-round-form
          :loading="loading"
          :financing-round="financingRound"
          :financing-round-id="financingRoundId"
          :is-submit="isSubmit"
          @save="save"
          @navToList="navToList"
        />
      </common-trs-card>
    </template>
  </common-trs-page-container>
</template>
<script>
  import * as captableService from '@/components/common/captable/services/captable'
  import FinancingRoundForm from './components/Form'
  export default {
    name: 'FinancingRoundFormIndex',
    components: {
      FinancingRoundForm,
    },
    data: () => ({
      loading: false,
      financingRound: {},
      financingRoundId: '',
      isSubmit: false,
    }),
    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },
    watch: {
      internalDialog: {
        handler (val) {
          if (val) {
            this.isSubmit = false
          }
        },
      },
    },
    methods: {
      async save (data) {
        this.loading = true
        try {
          const resp = await captableService.draftFinancingRound(this.captableId, data)
          if (resp.status === 201) {
            this.financingRoundId = resp.data.id
            this.isSubmit = true
          }
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to save financing round. Please try again later.',
          })
        }
      },
      navToList () {
        this.loading = false
        this.$store.dispatch('app/message', { text: 'Financing round saved successfully.' })
        this.$router.push({ name: 'FinancingRound' })
      },
      close () {
        this.$router.push({ name: 'FinancingRound' })
      },
    },
  }
</script>
